<template>
  <!-- Read view of the Contacts -->
  <div class="bg">
    <div class="row row-cols-md-2 row-cols-1">
      <div v-if="showName" id="first_name" class="col">
        <info-line label="First name">
          {{ contactObj.firstName }}
        </info-line>
      </div>
      <div v-if="showName" id="last_name" class="col">
        <info-line label="Last name">
          {{ contactObj.lastName }}
        </info-line>
      </div>
      <div v-if="showUsername" id="user_name" class="col">
        <info-line label="Username">
          {{ contactObj.email }}
        </info-line>
      </div>
      <div v-if="showPosition" id="headOfOrg_position" class="col">
        <info-line label="Position">
          {{ contactObj.position }}
        </info-line>
      </div>
    </div>
  </div>
</template>

<script>
import InfoLine from "../interface/InfoLine";

export default {
  name: "PersonalDetailView",
  components: {
    InfoLine,
  },
  props: ["contactObj"],
  computed: {
    showName() {
      return (
        this.contactObj &&
        this.contactObj.lastName &&
        this.contactObj.lastName !== ""
      );
    },
    showUsername() {
      return (
        this.contactObj && this.contactObj.email && this.contactObj.email !== ""
      );
    },
    contactName() {
      if (this.contactObj && this.contactObj.firstName) {
        return this.contactObj.firstName + " " + this.contactObj.lastName;
      } else return "";
    },
    showPosition() {
      return (
        this.contactObj &&
        this.contactObj.position &&
        this.contactObj.position !== ""
      );
    },
  },
};
</script>
