<template>
  <div class="pb-2">
    <label class="label-bold">{{ label }}</label>
    <div>
      <slot>{{ value }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoLine",
  props: ["label", "value"],
};
</script>

<style scoped>
label.label-bold {
  margin-bottom: 0;
}
</style>
